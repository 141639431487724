import { Flex, Text } from "@chakra-ui/react";
import { NewsCardImage } from "./NewsCardImage";
import { t } from "i18next";
import { Article } from "../../../types";
import { Link } from "react-router-dom";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";

export function WideNewsCard({
  preview_image_url,
  url,
  title,
  category,
  author: { name },
  largeVariant = false,
}: Article & { largeVariant?: boolean }) {
  const isSmallBreakpoint = useIsSmallBreakpoint();

  return (
    <Flex
      width="100%"
      display="flex"
      gap={largeVariant ? "40px" : "32px"}
      height="min-content"
    >
      {!isSmallBreakpoint && (
        <Link to={new URL(url).pathname} style={{ width: "50%" }}>
          <NewsCardImage
            imageProps={{
              src: preview_image_url,
              title: title,
              flexShrink: 0,
              objectFit: "contain",
              borderRadius: largeVariant ? "16px" : "12px",
            }}
          />
        </Link>
      )}
      <Flex direction="column" gap="8px" justifyContent="center" flex={1}>
        {category && (
          <Link to={`/news/${category.slug}`}>
            <Text variant="newsCardVital" color="blaze.blaze">
              {category.display_name}
            </Text>
          </Link>
        )}
        <Link to={new URL(url).pathname}>
          <Text
            variant="newsCardTitle"
            fontSize={
              largeVariant ? "40px" : isSmallBreakpoint ? "16px" : "24px"
            }
            sx={{ "-webkit-line-clamp": largeVariant ? "4" : "2" }}
          >
            {title}
          </Text>
        </Link>
        <Link to={`/u/${name}`}>
          <Text variant="newsCardVital">
            {t("components.newsCards.by", { name })}
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
}
