import { useInfiniteQuery } from "@tanstack/react-query";
import NewsContentGrid from "./components/NewsContentGrid";
import { ScreenLayout } from "../../components/layouts/components/ScreenLayout";
import { Flex, Spinner } from "@chakra-ui/react";
import { getNextPageParam, getPreviousPageParam } from "../../utils/api";
import { CollapsibleNavBar } from "../../components/navbar/CollapsibleNavBar";
import { useIsSmallBreakpoint } from "../../utils/useBreakpoints";
import { NewsCategoriesCarousel } from "./components/NewsCategoriesCarousel";
import { useAccountData } from "../../hooks/useAccountData";
import { getNews } from "./api/newsScreenApi";
import { useNewsCategories } from "./hooks/useNewsCategories";
import { BrowseEmptyState } from "../browse/components/EmptyState";
import { useScrollVisibility } from "../../hooks/useScrollVisibility";
import { useEffect, useRef } from "react";
import { t } from "i18next";

const GET_NEWS_QUERY_KEY = "GET_NEWS_QUERY_KEY";
export default function NewsScreen() {
  const accountData = useAccountData();
  const isSmallBreakpoint = useIsSmallBreakpoint();

  const { activeCategory } = useNewsCategories();

  const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryFn: (ctx) => {
        const pageParam = ctx.pageParam;
        return getNews({ categoryId: activeCategory?.id, pageParam });
      },
      queryKey: [GET_NEWS_QUERY_KEY, activeCategory, accountData?.user?.id],
      initialPageParam: 1,
      getNextPageParam,
      getPreviousPageParam,
    });

  // Scroll handler
  useEffect(() => {
    const container = scrollRef.current;

    const handleScroll = () => {
      if (!container || isFetchingNextPage || !hasNextPage) return;

      const { scrollTop, scrollHeight, clientHeight } = container;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 50;

      if (isBottom) {
        fetchNextPage();
      }
    };

    container?.addEventListener("scroll", handleScroll);
    return () => container?.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, isFetchingNextPage]);

  const pages = data?.pages;
  const articles = pages?.flatMap((p) => p.data.payload.results);

  const scrollRef = useRef<HTMLDivElement>(null);
  const { handleScroll, isHidden } = useScrollVisibility();

  return (
    <ScreenLayout pageTitle={t("screens.news.title")} hideSearch>
      <Flex
        gap={isSmallBreakpoint ? 0 : "40px"}
        direction="column"
        width="full"
        height="100%"
      >
        <CollapsibleNavBar
          isCollapsed={isHidden}
          shouldCollapse={isSmallBreakpoint}
        >
          <Flex
            justifyContent="space-between"
            pl={isSmallBreakpoint ? 4 : 0}
            pr={isSmallBreakpoint ? 4 : 6}
          >
            <NewsCategoriesCarousel />
          </Flex>
        </CollapsibleNavBar>
        {!data || isLoading || !articles?.length ? (
          <Flex
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            {data && !isLoading ? (
              <BrowseEmptyState />
            ) : (
              <Spinner color="blaze.blaze" />
            )}
          </Flex>
        ) : (
          <Flex
            ref={scrollRef}
            width="100%"
            height={`calc(100vh - ${isSmallBreakpoint ? (isHidden ? 25 : 120) : 255}px)`}
            overflow="auto"
            padding={isSmallBreakpoint ? "16px" : undefined}
            paddingBottom="40px"
            paddingRight={isSmallBreakpoint ? undefined : "40px"}
            flexDirection="column"
            onScroll={() => {
              if (scrollRef.current) {
                handleScroll(scrollRef.current);
              }
            }}
          >
            <NewsContentGrid
              articles={articles ?? []}
              isFetchingNextPage={isFetchingNextPage}
            />
          </Flex>
        )}
      </Flex>
    </ScreenLayout>
  );
}
