import { Divider, Grid, GridItem, Spinner } from "@chakra-ui/react";
import { Article, LayoutStyle } from "../../../types";
import { NewsCard } from "../../../components/news/NewsCard";
import { useIsSmallBreakpoint } from "../../../utils/useBreakpoints";
import { useCallback } from "react";

export default function NewsContentGrid({
  articles,
  columnCount = 6,
  isFetchingNextPage = false,
}: {
  articles: Article[];
  columnCount?: number;
  isFetchingNextPage?: boolean;
}) {
  const isSmallBreakpoint = useIsSmallBreakpoint();

  const getColumnSpan = useCallback(
    (columnCount: number, size: LayoutStyle["size"]) => {
      switch (size) {
        case "l":
        case "s":
          return columnCount / (isSmallBreakpoint ? 1 : 2);
        case "m":
          return columnCount / (isSmallBreakpoint ? 2 : 3);
        default:
          return columnCount;
      }
    },
    [isSmallBreakpoint],
  );

  const getRowGap = useCallback(
    (size: LayoutStyle["size"]) => {
      switch (size) {
        case "s":
          return isSmallBreakpoint ? "16px" : "48px";
        default:
          return isSmallBreakpoint ? "24px" : "48px";
      }
    },
    [isSmallBreakpoint],
  );

  const getColumnGap = useCallback(
    (size: LayoutStyle["size"]) => {
      switch (size) {
        case "s":
          return isSmallBreakpoint ? "8px" : "24px";
        default:
          return isSmallBreakpoint ? "12px" : "24px";
      }
    },
    [isSmallBreakpoint],
  );

  const extraLarge = articles.slice(0, 1);
  const large = articles.slice(1, 5);
  const medium = articles.slice(5, 17);
  const small = articles.slice(17);
  return (
    <>
      <Grid
        templateColumns={`repeat(${columnCount}, 1fr)`}
        columnGap={getColumnGap("xl")}
        rowGap={getRowGap("xl")}
      >
        {extraLarge.map((article) => (
          <GridItem key={article.id} colSpan={getColumnSpan(columnCount, "xl")}>
            <NewsCard content={article} size="xl" />
          </GridItem>
        ))}
      </Grid>

      <Divider
        marginTop={isSmallBreakpoint ? "12px" : "48px"}
        marginBottom={isSmallBreakpoint ? "12px" : "48px"}
        colorScheme="transparent.white.10"
        opacity={isSmallBreakpoint ? 0 : 1}
      />

      <Grid
        templateColumns={`repeat(${columnCount}, 1fr)`}
        columnGap={getColumnGap("l")}
        rowGap={getRowGap("l")}
      >
        {large.map((article) => (
          <GridItem key={article.id} colSpan={getColumnSpan(columnCount, "l")}>
            <NewsCard content={article} size="l" />
          </GridItem>
        ))}
      </Grid>

      <Divider
        my={isSmallBreakpoint ? "24px" : "48px"}
        colorScheme="transparent.white.10"
      />

      <Grid
        templateColumns={`repeat(${columnCount}, 1fr)`}
        gap={isSmallBreakpoint ? "16px" : "24px"}
        columnGap={getColumnGap("m")}
        rowGap={getRowGap("m")}
      >
        {medium.map((article) => (
          <GridItem key={article.id} colSpan={getColumnSpan(columnCount, "m")}>
            <NewsCard content={article} size="m" />
          </GridItem>
        ))}
      </Grid>

      <Divider
        marginTop={isSmallBreakpoint ? "24px" : "48px"}
        marginBottom={isSmallBreakpoint ? "16px" : "48px"}
        colorScheme="transparent.white.10"
      />

      <Grid
        templateColumns={`repeat(${columnCount}, 1fr)`}
        gap={isSmallBreakpoint ? "16px" : "24px"}
        columnGap={getColumnGap("s")}
        rowGap={getRowGap("s")}
      >
        {small.map((article, i) => (
          <>
            <GridItem
              key={article.id}
              colSpan={getColumnSpan(columnCount, "s")}
            >
              <NewsCard content={article} size="s" />
            </GridItem>

            {isSmallBreakpoint && i < small.length - 1 && (
              <GridItem colSpan={columnCount}>
                <Divider colorScheme="transparent.white.10" width="100%" />
              </GridItem>
            )}
          </>
        ))}
        {isFetchingNextPage ? (
          <GridItem
            colSpan={getColumnSpan(columnCount, "s")}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Spinner color="blaze.blaze" />
          </GridItem>
        ) : null}
      </Grid>
    </>
  );
}
