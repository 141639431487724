import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Flex, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface BreadcrumbNavProps {
  path: Array<{
    name: string;
    url: string;
  }>;
}
export function BreadcrumbNav({ path }: BreadcrumbNavProps) {
  return (
    <Flex alignItems="center" gap="4px">
      {path.map((crumb, i) => {
        return (
          <>
            <Link
              as={RouterLink}
              to={crumb.url}
              color="dune.700"
              _hover={{ color: "dune.dune" }}
              fontSize="12px"
              fontWeight="500"
              letterSpacing=".5px"
              fontFamily="Bricolage Grotesque"
              transition="all .1s linear"
              whiteSpace="nowrap"
              textOverflow={i === path.length - 1 ? "ellipsis" : undefined}
              overflow={i === path.length - 1 ? "hidden" : undefined}
            >
              {crumb.name}
            </Link>
            {i < path.length - 1 && (
              <ArrowForwardIcon color="dune.700" boxSize="12px" />
            )}
          </>
        );
      })}
    </Flex>
  );
}
