import { router } from "./screens/router";

import { useHydrateAppStore, useHydrateUserAccount } from "./appStore";
import "./i18n";

import { loadStripe } from "@stripe/stripe-js";
import { RouterProvider } from "react-router";
import TimerProvider from "./screens/root/components/TimerContext";
export const stripePromise = loadStripe(window.gc.global.stripe_key);

function App() {
  useHydrateUserAccount();
  useHydrateAppStore();

  return (
    <TimerProvider>
      <RouterProvider router={router} />
    </TimerProvider>
  );
}

export default App;
