import { useQuery } from "@tanstack/react-query";
import { getRelease, useSlideInMenu } from "../hooks/useSlideInMenu";
import { Flex, Image, Link, Text } from "@chakra-ui/react";
import { TitleWithGearIcon } from "../../../screens/reader/components/menuTitles";
import { useTranslation } from "react-i18next";
export function ReleaseInfoMenuTitle() {
  const { t } = useTranslation();
  const { releaseUuid, openComicInfoMenu } = useSlideInMenu();
  const { data: fetchedRelease } = useQuery({
    queryKey: ["releaseDetailsRelease", releaseUuid],
    queryFn: () => {
      return getRelease(releaseUuid ?? "");
    },
    networkMode: "offlineFirst",
  });

  const comic = fetchedRelease?.data.payload.results?.comic;

  if (!comic) {
    return (
      <TitleWithGearIcon
        title={t("components.slideInMenu.releaseInfoMenu.releaseInfo")}
        label={t("components.slideInMenu.releaseInfoMenu.editRelease")}
        enabled={false}
        editUrl={""}
      />
    );
  }
  return (
    <Flex flexDirection="row" gap="8px" width="100%" alignItems="center">
      <Image
        width="22px"
        height="34px"
        src={comic.image_tiny_url}
        alt={`Comic cover for ${comic.name}`}
        borderRadius="2px"
        border="1px solid"
        borderColor="transparent.white.10"
        marginRight="8px"
      />
      <Flex flexDirection="column" width="100%">
        <Text
          fontSize="18px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="130%"
        >
          {comic.name}
        </Text>
        <Link
          fontSize="10px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="150%"
          letterSpacing="0.25px"
          color="blaze.300"
          transition="all .1s linear"
          _hover={{
            color: "blaze.blaze",
          }}
          onClick={() => openComicInfoMenu(comic.slug)}
        >
          {t("components.slideInMenu.releaseInfoMenu.viewSeriesInfo")}
        </Link>
      </Flex>
    </Flex>
  );
}
